import React from 'react'
import { Link } from 'react-router-dom';
import '../Footer/Footer.css';

export default function Footer() {
  return (
    <>
    <div className="footer d-flex justify-content-between w-100">
    <div className="footer-section">
      <h4 className='text-white text-uppercase'>Get in Touch</h4>
      <ul>
        <li><Link className='text-white font-18 font-thin' to="/contact">contact us</Link></li>
        <li><Link className='text-white font-18 font-thin' to="/instagram">instagram</Link></li>
      </ul>
    </div>
    <div className="footer-section">
      <h4 className='text-white text-uppercase'>About Ivey</h4>
      <ul>
        <li><Link className='text-white font-18 font-thin' to="/projects">projects</Link></li>
        <li><Link className='text-white font-18 font-thin' to="/about">about ivey</Link></li>
        {/* <li><Link className='text-white font-18 font-thin' to="/our-team">our team</Link></li> */}
      </ul>
    </div>
    <div className="footer-section">
      <h4 className='text-white text-uppercase'>Services</h4>
      <ul>
        <li><Link className='text-white font-18 font-thin' to="/about#about-services">our serices</Link></li>
        {/* <li><Link className='text-white font-18 font-thin' to="/technology">technolog at Ivey</Link></li> */}
        <li><Link className='text-white font-18 font-thin' to="/technology">the ivey experience</Link></li>
      </ul>
    </div>
    <div className="footer-section text-white">
      <h3>Stay in touch, get inspired</h3>
      <p>We post about our experience and what’s new in the industry every so often. Sign up for our newsletter to keep up. </p>
      <form className="newsletter-signup">
        <input type="email" placeholder="email"/>
        <button type="submit" className='sign-up-btn'>Sign up</button>
      </form>
    </div>
  </div>
      
    </>
  )
}
