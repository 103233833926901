import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import config from "../../src/config";
import { Link } from 'react-router-dom';

export default function FilterProject() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get(`${config.BASE_URL}/api/admin/get-projects`, {
                    withCredentials: true
                });
                setProjects(response.data); // Assuming `projects` is an array in the response
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };
        fetchProjects();
    }, []);

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 800,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0, 
        centerMode: true,
        autoplay: true,        
        autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 767,
              settings: { slidesToShow: 2 }
            },
            {
                breakpoint: 480,
                settings: { slidesToShow: 1 }
            },
        ]
    };

    return (
        <>
            <div className='home-recent-projet-sec filter-project-sec d-flex w-100 bg-white'>
                <div className='home-recent-projet-left d-flex flex-column'>
                    {projects.slice(0, 4).map((project, index) => (
                        <Link className='text-black' to={`/single-project/${project.slug}`}>
                            <div className='home-recent-project-item' key={index}>
                                <div className='recent-featured-img'>
                                    <img src={`${config.BASE_URL}${project.featuredImage}`}  alt="featured" /> {/* Use project image or fallback */}
                                </div>
                                <div className='recent-project-detail d-flex'>
                                    <span className='project-name font-11 font-medium text-uppercase text-black'>{project.title || "Project Name"}</span>
                                    <span className='project-scale font-11 text-black'>{project.location} · {project.space} sq ft</span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>

                <div className='home-recent-projet-center d-flex flex-column'>
                    {projects.slice(4, 6).map((project, index) => (
                        <Link className='text-black' to={`/single-project/${project.slug}`}>
                            <div className='home-recent-project-item' key={index}>
                                <div className='recent-featured-img'>
                                    <img src={`${config.BASE_URL}${project.featuredImage}`} alt="featured" />
                                </div>
                                <div className='recent-project-detail d-flex'>
                                    <span className='project-name font-14 font-medium text-uppercase text-black'>{project.title}</span>
                                    <span className='project-scale font-14 text-black'>{project.location} · {project.space} sq ft</span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>

                <div className='home-recent-projet-right d-flex flex-column'>
                    {projects.slice(6, 10).map((project, index) => (
                        <Link className='text-black' to={`/single-project/${project.slug}`}>
                            <div className='home-recent-project-item' key={index}>
                                <div className='recent-featured-img'>
                                    <img src={`${config.BASE_URL}${project.featuredImage}`} alt="featured" />
                                </div>
                                <div className='recent-project-detail d-flex'>
                                    <span className='project-name font-11 font-medium text-uppercase text-black'>{project.title}</span>
                                    <span className='project-scale font-11 text-black'>{project.location} · {project.space} sq ft</span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

            <div className='home-recent-projet-slider filter-project-slider-mobile'>
                <Slider {...sliderSettings}>
                    {projects.map((project, index) => (
                        <Link className='text-black' to={`/single-project/${project.slug}`}>
                            <div className='home-recent-project-item' key={index}>
                                <div className='recent-featured-img'>
                                    <img src={`${config.BASE_URL}${project.featuredImage}`} alt="featured" />
                                </div>
                                <div className='recent-project-detail d-flex'>
                                    <span className='project-name font-11 font-medium text-uppercase text-black'>{project.title}</span>
                                    <span className='project-scale font-11 text-black'>{project.location} · {project.space} sq ft</span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </Slider>
            </div>
        </>
    );
}
