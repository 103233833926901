import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import config from "../../src/config"
import axios from 'axios';


export default function HomeTestimonial() {



  const [testimonials, setTestimonials] = useState([]);

  // Fetch testimonials data on component mount
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/api/admin/get-testimonials`, {
          withCredentials: true
        });
        

        setTestimonials(response.data); // Assuming response.data contains the array of testimonials
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);
  
  const slidesToShow = testimonials.length <= 5 ? 3 : 5;

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '120px',
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '100px',
        }
      },
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 1,
      //     centerMode: true,
      //     centerPadding: '50px',
      //   }
      // },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '130px',
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '50px',
        }
      }
    ]
  };
  return (
    <>
      <div className="testimonial-slider position-relative">
        <span className='font-10 testimonial-title position-absolute'>FROM OUR CLIENTS</span>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-slide" key={index}>
              <div className='testimonial-slide-img'>
                <img src={`${config.BASE_URL}${testimonial.featuredImage}`} alt={`Slide ${index + 1}`} />
              </div>
              <div className='testimonial-desc'>
                <h2 className='text-white'>“{testimonial.title}”</h2>
                <p className='font-14 text-white'>{testimonial.description}</p>
                <span className='client-name text-white font-10'>{testimonial.clientName}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}
