import React from 'react';
import inspirationBanner from '../assets/images/inspobanner.png';

export default function InspirationBanner() {
  return (
    <>
    <div className='inspiration-banner-sec position-relative text-white' style={{ backgroundImage: `url(${inspirationBanner})` }}>
        <div className='inspiration-banner-inner'>
            <h3>a natural yet experimental cabin and artist retreat</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
        </div>
    </div>
    </>
  )
}
