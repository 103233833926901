import React, { useState, useEffect } from 'react';
import "../Header/header.css";
import { Link } from 'react-router-dom';
import menuIcon from "../../assets/images/menu-icon.svg";
import closeIcon from "../../assets/images/close-icon.png";
import logourl from '../../assets/images/logo.png';
import featuredimage1 from '../../assets/images/feautred-image1.jpg';
import featuredimage2 from '../../assets/images/featuredimage2.jpg';
import featuredimage3 from '../../assets/images/featured-image3.jpg';
import blacklogo from '../../assets/images/IVEYblacklogo.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import config from '../../config';
import axios from 'axios';

export default function Navbar(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Track selected tab
  const [menuItems, setMenuItems] = useState([
    { name: '', subMenus: [{ name: '', link: '' }] } // Initialize with link key
  ]);

  const toggleMenu = (event) => {
    event.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.classList.add('menu-open');
    } else {
      document.documentElement.classList.remove('menu-open');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
        document.documentElement.classList.add('header-scrolled');
      } else {
        setIsScrolled(false);
        document.documentElement.classList.remove('header-scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);


  const fetchMenuDetails = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}/api/admin/get-menu-items/`, { withCredentials: true });

      if (response.data.length > 0) {
        setMenuItems(response.data[0].menuData);
        // setMenuId(response.data[0]._id);
        // setAddNew(false);
      } else {
        // setAddNew(true);
      }
    } catch (err) {
      // setError('Failed to fetch location details');
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchMenuDetails();
  }, []);

  const megamenusliderSettings = {
    dots: false,
    infinite: true,
    speed: 700,
    arrows: false,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    centerMode: false,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ]
  };

  // Content for each tab, adjust as needed
  const tabContent = [
    {
      leftLink: '/projects',
      leftText: 'Design projects',
      leftTitle: 'build project',
      sliderContent: (
        <div className='mega-menu-slider'>
          <Slider {...megamenusliderSettings}>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage1} alt="featured" />
              </div>
              <div className='recent-project-detail d-flex'>
                <span className='project-name font-11 font-medium text-uppercase text-black'>RITZ CARLTON 0228</span>
                <span className='project-scale font-11 text-black'>miami · 550 sq ft</span>
              </div>
            </div>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage2} alt="featured" />
              </div>
              <div className='recent-project-detail d-flex'>
                <span className='project-name font-11 font-medium text-uppercase text-black'>RITZ CARLTON 0228</span>
                <span className='project-scale font-11 text-black'>miami · 550 sq ft</span>
              </div>
            </div>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage3} alt="featured" />
              </div>
              <div className='recent-project-detail d-flex'>
                <span className='project-name font-11 font-medium text-uppercase text-black'>RITZ CARLTON 0228</span>
                <span className='project-scale font-11 text-black'>miami · 550 sq ft</span>
              </div>
            </div>
          </Slider>
          {/* Add additional slides as needed */}
        </div>
      )
    },
    {
      leftLink: '/service',
      leftText: 'All services',
      leftTitle: 'build service',
      sliderContent: (
        <div className='mega-menu-slider'>
          <Slider {...megamenusliderSettings}>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage1} alt="featured" />
              </div>
              <div className='recent-project-detail d-flex'>
                <span className='project-name font-11 font-medium text-uppercase text-black'>RITZ CARLTON 0228</span>
              </div>
            </div>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage2} alt="featured" />
              </div>
              <div className='recent-project-detail d-flex'>
                <span className='project-name font-11 font-medium text-uppercase text-black'>RITZ CARLTON 0228</span>
              </div>
            </div>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage3} alt="featured" />
              </div>
              <div className='recent-project-detail d-flex'>
                <span className='project-name font-11 font-medium text-uppercase text-black'>RITZ CARLTON 0228</span>
              </div>
            </div>
          </Slider>
          {/* Add additional slides as needed */}
        </div>
      )
    },
    {
      leftLink: '/about',
      leftText: 'About us',
      sliderContent: (
        <div className='mega-menu-slider'>
          <Slider {...megamenusliderSettings}>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage1} alt="featured" />
              </div>
            </div>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage2} alt="featured" />
              </div>
            </div>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage3} alt="featured" />
              </div>
            </div>
          </Slider>
          {/* Add additional slides as needed */}
        </div>
      )
    },
    {
      leftLink: '/inspiration',
      leftText: 'Inspiration',
      sliderContent: (
        <div className='mega-menu-slider'>
          <Slider {...megamenusliderSettings}>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage1} alt="featured" />
              </div>
            </div>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage2} alt="featured" />
              </div>
            </div>
            <div className='home-recent-project-item'>
              <div className='recent-featured-img'>
                <img src={featuredimage3} alt="featured" />
              </div>
            </div>
          </Slider>
          {/* Add additional slides as needed */}
        </div>
      )
    },
    {
      leftLink: '/contact',
      leftText: 'Contact us',
      sliderContent: (
        <div className='mega-menu-slider'>
          <div className='home-recent-project-item'>
            <div className='recent-featured-img'>
              <img src={featuredimage2} alt="featured" />
            </div>
          </div>
          {/* Add additional slides as needed */}
        </div>
      )
    },
    // Add additional objects for other tabs
  ];


  return (
    <div className={isScrolled ? 'header scrolled' : 'header'}>
      <div className="container">
        <div className="header-main d-flex justify-content-between align-items-center">
          <div className="header-left d-flex align-items-center text-white border-right">

            {props.page === "home" && (
              <div className='user-detail'>
                <span className='user-name'>miami</span>
                <span className='time-sec'>6:13pm</span>
              </div>
            )}
            {isMobile && (props.page !== "home") && (
              <div className='user-detail'>
                <span className='user-name'>miami</span>
                <span className='time-sec'>6:13pm</span>
              </div>
            )}
            {!isMobile && (props.page !== "home") && (
              <Link to="/" className="header-logo header-logo-project">
                <img src={logourl} alt="header-logo" />
              </Link>
            )}
          </div>
          {/* <Link to="/" className="header-logo">
              <img src={logourl} alt="header-logo" />
            </Link> */}
          <div className="header-right d-flex align-items-center border-right">
            <Link to="/contact" className="header-contact  text-white">contact</Link>
            <Link className="navbar-toggler text-white" to="/" onClick={toggleMenu}>
              <span className="navbar-toggler-icon">
                {isMenuOpen ? <img src={closeIcon} alt="close-icon" /> : <img src={menuIcon} alt="menu-icon" />}
              </span>
            </Link>
          </div>
          <div className={`header-navbar ${isMenuOpen ? 'show' : ''}`}>
            <Link to="/" className="header-logo-menu">
              <img src={blacklogo} alt="header-logo" />
            </Link>
            <div className='mega-menu-botm d-flex'>
              <div className='mega-menu-tabs text-black'>
                <ul className='mega-menu-tab-title'>
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`font-12 text-uppercase ${selectedTabIndex === index ? 'active' : ''}`}
                      onClick={() => setSelectedTabIndex(index)}
                    >
                      <span>{item.name}</span> 
                    </li>
                  ))}
                </ul>
              </div>
              <div className='mega-menu-tab-panel'>
                <div className='tab-panel-desc'>
                  <div className='tab-panel-desc-left'>
                    
                    {menuItems[selectedTabIndex].subMenus.map(item => (
                      <>
                      <Link to={item.link} className='font-18 menu-link font-medium'>
                        {item.name}
                      </Link>
                      <br/>
                      </>

                    ))}

                    {/* <span className='font-18 menu-title'>{tabContent[selectedTabIndex].leftTitle}</span> */}
                  </div>
                  <div className='tab-panel-desc-right'>
                    {tabContent[selectedTabIndex].sliderContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
