import React from 'react'
import Layout from './layout'
import InspirationBanner from '../components/InspirationBanner'
import InspoDetail from '../components/InspoDetail'

export default function Inspiration() {
  return (
    <>
    <Layout page={"inspiration"}>
        <div className='inspo-desc bg-white d-flex justify-content-center align-items-center'>
            <h6 className='font-11 text-uppercase font-medium'>INSPIRATION</h6>
            <h2>what’s on our mind and moodboards from the design and build world</h2>
        </div>
        <InspoDetail />
        <InspirationBanner />
        <div className='inspo-detail-botm bg-white'>
            <InspoDetail />
        </div>

    </Layout>
      
    </>
  )
}
