import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function SingleProjectGallery({project}) {
    const [featuredImages, setFeaturedImages] = useState([]);
    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: '220px',
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            { breakpoint: 1399, settings: { slidesToShow: 3, centerPadding: '120px' } },
            { breakpoint: 1024, settings: { slidesToShow: 2, centerPadding: '140px' } },
            { breakpoint: 991, settings: { centerPadding: '100px' } },
            { breakpoint: 767, settings: { slidesToShow: 2, centerPadding: '80px' } },
            { breakpoint: 575, settings: { slidesToShow: 1, centerPadding: '60px' } },
        ],
    };

    console.log("project: ", project);

    useEffect(() => {
        if(project){
            setFeaturedImages(project?.galleryImages)
        }
    }, [project]);

    // const [projects, setProjects] = useState([]);
    // const [galleryImages, setGalleryImages] = useState([]);

    // useEffect(() => {
    //     const fetchProjects = async () => {
    //         try {
    //             const response = await axios.get(`${config.BASE_URL}/api/admin/get-projects`, {
    //                 withCredentials: true,
    //             });
    //             setProjects(response.data);

    //             const allGalleryImages = response.data.reduce((acc, project) => {
    //                 return acc.concat(project.galleryImages);
    //             }, []);
    //             setGalleryImages(allGalleryImages);
    //         } catch (error) {
    //             console.error('Error fetching projects:', error);
    //         }
    //     };

    //     fetchProjects();
    // }, []);
    // const featuredImages = galleryImages.slice(0, 6);

    return (
        <>
            <div className='single-project-featured-gallery d-grid'>
                <div className='single-project-featured-gallery-items'>
                    <div className='single-project-featured-gallery-img featured-large-img'>
                        {featuredImages[0] && (
                            <img src={`${config.BASE_URL}${featuredImages[0]}`} alt="gallery" />
                        )}
                    </div>
                    <div className='single-project-featured-gallery-img'>
                        {featuredImages[1] && (
                            <img src={`${config.BASE_URL}${featuredImages[1]}`} alt="gallery" />
                        )}
                    </div>
                    <div className='single-project-featured-gallery-img'>
                        {featuredImages[2] && (
                            <img src={`${config.BASE_URL}${featuredImages[2]}`} alt="gallery" />
                        )}
                    </div>
                </div>
                <div className='single-project-featured-gallery-items'>
                    <div className='single-project-featured-gallery-img'>
                        {featuredImages[3] && (
                            <img src={`${config.BASE_URL}${featuredImages[3]}`} alt="gallery" />
                        )}
                    </div>
                    <div className='single-project-featured-gallery-img'>
                        {featuredImages[4] && (
                            <img src={`${config.BASE_URL}${featuredImages[4]}`} alt="gallery" />
                        )}
                    </div>
                    <div className='single-project-featured-gallery-img featured-large-img'>
                        {featuredImages[5] && (
                            <img src={`${config.BASE_URL}${featuredImages[5]}`} alt="gallery" />
                        )}
                    </div>
                </div>
            </div>

            <div className='single-project-gallery-slider position-relative'>
                <Slider {...settings}>
                    {featuredImages.map((image, index) => (
                        <div key={index} className='single-project-gallery-img'>
                            <img src={`${config.BASE_URL}${image}`} alt={`gallery ${index}`} />
                        </div>
                    ))}
                </Slider>
            </div>

        </>
    )
}
