import React from 'react';
import inspoimage1 from '../assets/images/inspimg1.jpg';
import inspoimage2 from '../assets/images/inspoimg2.jpg';
import inspoimage3 from '../assets/images/inspoimg3.jpg';

export default function InspoDetail() {
  return (
    <>
    <div className='inspo-detail-sec d-grid bg-white'>
        <div className='inspo-detail-items inspo-detail-large-items  d-flex'>
            <div className='inspo-detail-item-img'>
                <img src={inspoimage1} alt="inspo-img" />
            </div>
            <div className='inspo-detail-item-desc'>
                <h3>a natural yet experimental cabin and artist retreat</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
            </div>
        </div>
        <div className='inspo-detail-items d-flex'>
            <div className='inspo-detail-item-img'>
                <img src={inspoimage2} alt="inspo-img" />
            </div>
            <div className='inspo-detail-item-desc'>
                <h3>a natural yet experimental cabin and artist retreat</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
            </div>
        </div>
        <div className='inspo-detail-items d-flex'>
            <div className='inspo-detail-item-img'>
                <img src={inspoimage3} alt="inspo-img" />
            </div>
            <div className='inspo-detail-item-desc'>
                <h3>a natural yet experimental cabin and artist retreat</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
            </div>
        </div>
    </div>

      
    </>
  )
}
